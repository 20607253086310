<template>
  <h1>{{ msg }}</h1>
  <div class="content" id="content">
      <div class="xe-card col-sm-4 col-md-3 ">
          <a href="https://blog.ilirui.cn" target="_blank" class="xe-widget xe-conversations box2 label-info" data-toggle="tooltip" data-placement="bottom" title="感官先生's Blog">
              <div class="xe-comment-entry">
                  <div class="xe-user-img">
                      <img class="img-circle lazy" src="https://blog.ilirui.cn/wp-content/uploads/2022/07/cropped-微信图片_20220704212350.jpg"  width="40">
                  </div>
                  <div class="xe-comment">
                      <div class="xe-user-name overflowClip_1">
                          <strong>感官先生's Blog</strong>
                      </div>
                      <p class="overflowClip_2">热爱，可抵岁月漫长</p>
                  </div>
              </div>
          </a>
      </div>
      <div class="xe-card col-sm-4 col-md-3 ">
          <a href="https://music.ilirui.cn" target="_blank" class="xe-widget xe-conversations box2 label-info" data-toggle="tooltip" data-placement="bottom" title="YesPlayMusic">
              <div class="xe-comment-entry">
                  <div class="xe-user-img">
                      <img class="img-circle lazy" src="https://music.ilirui.cn/img/icons/android-chrome-192x192.png" width="40">
                  </div>
                  <div class="xe-comment">
                      <div class="xe-user-name overflowClip_1">
                          <strong>YesPlayMusic</strong>
                      </div>
                      <p class="overflowClip_2">高颜值的第三方网易云播放器</p>
                  </div>
              </div>
          </a>
      </div>
      <div class="xe-card col-sm-4 col-md-3 ">
          <a href="https://book.ilirui.cn" target="_blank" class="xe-widget xe-conversations box2 label-info" data-toggle="tooltip" data-placement="bottom" title="Reader3">
              <div class="xe-comment-entry">
                  <div class="xe-user-img">
                      <img class="img-circle lazy" src="https://book.ilirui.cn/img/icons/android-chrome-192x192.png" width="40">
                  </div>
                  <div class="xe-comment">
                      <div class="xe-user-name overflowClip_1">
                          <strong>Reader3</strong>
                      </div>
                      <p class="overflowClip_2">小说阅读</p>
                  </div>
              </div>
          </a>
      </div>
  </div>
  <div class="note">
    <p>Copyright © 2022-{{ currentYear }} 亦见</p>
    <p>备案号：赣ICP备2024019642号</p>
    <p>本网址导航页已经运行: {{ uptime }}</p>
  </div>
</template>

<script>
export default {
  name: 'urlContent',
  props: {
    msg: String
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      startDate: new Date(2024, 0, 13, 15, 34), //月份从0开始，0是1月
      uptime: '',
    };
  },
  mounted() {
    this.calculateUptime();
    setInterval(this.calculateUptime, 1000);
  },
  methods: {
    calculateUptime() {
      const now = new Date();
      let uptime = now - this.startDate;

      uptime = Math.max(uptime, 0); // 处理负数情况

      const years = Math.floor(uptime / (365 * 24 * 60 * 60 * 1000));
      const days = Math.floor((uptime % (365 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000));
      const hours = Math.max(0, Math.floor((uptime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)));
      const minutes = Math.max(0, Math.floor((uptime % (60 * 60 * 1000)) / (60 * 1000)));
      const seconds = Math.max(0, Math.floor((uptime % (60 * 1000)) / 1000));

      this.uptime = years + " 年 " + days + " 天 " + hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#content {
    display: flex;
    justify-content: space-around;
    margin-top: 70px;
}

.note {
  position: absolute;
  right:0;
  bottom:50px;
  left:0;
  align-content: center;
  alignment: center;
}

a {
    color: #282828;
    outline: 0 !important
}

h1 {
    margin-top: 80px;
}

.xe-card {
    position: relative;
    height: 86px;
    width: 400px;
    margin: 55px 0 0 0
}

.xe-widget {
    position: relative
}

.xe-widget.xe-conversations {
    position: absolute;
    background: #fff;
    margin-bottom: 0px;
    padding: 15px;
    left: 0;
    right: 0;
    margin: 0 15px
}

.box2 {
    height: 86px;
    border-radius: 4px;
    padding: 0px 30px 0px 30px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e4ecf3;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.box2:hover {
    box-shadow: 0 26px 40px -24px rgba(0,36,100,.3);
    -webkit-box-shadow: 0 26px 40px -24px rgba(0,36,100,.3);
    -moz-box-shadow: 0 26px 40px -24px rgba(0,36,100,.3);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease
}

</style>
